<template>
  <label class="checkbox">
    <input class="checkbox__input" type="checkbox" :disabled="disabled" v-model="value" @change="update">
    <span class="checkbox__checkmark"></span>
    {{ label }}
  </label>
</template>

<script>
export default {
  name: 'AppCheckbox',
  props: {
    modelValue: Boolean,
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: false
    }
  },
  methods: {
    update() {
      this.$emit('update:modelValue', this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  font-size: 13px;
  color: #333;
  cursor: pointer;
}

.checkbox__input {
  display: none;
}

.checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1.5px solid $primary-links-color;
  border-radius: 4px;
}

.checkbox__input:checked ~ .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: -1.5px;
  top: -1.5px;
  width: calc(100% + 3px);
  height: calc(100% + 3px);
  background-image: url("../assets/svg/checkbox.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (min-width: 576px) {
  .checkbox {
    font-size: 15px;
  }
}

@media screen and (min-width: 576px) {
  .checkbox {
    font-size: 16px;
  }
}
</style>