<template>
  <wrapper>
    <app-header/>
    <container class="profile">
      <div class="profile__skeleton skeleton" v-if="! $store.getters['user/isProfileDownloaded']">
        <div class="skeleton__header skeleton-element"></div>
        <div class="skeleton__info-container">
          <div class="skeleton__info skeleton-element"></div>
          <div class="skeleton__info skeleton-element"></div>
          <div class="skeleton__info skeleton-element"></div>
          <div class="skeleton__info skeleton-element"></div>
          <div class="skeleton__info skeleton-element"></div>
          <div class="skeleton__info skeleton-element"></div>
          <div class="skeleton__info skeleton-element"></div>
          <div class="skeleton__info skeleton-element"></div>
          <div class="skeleton__info skeleton-element"></div>
        </div>
        <div class="skeleton__header skeleton-element"></div>
        <div class="skeleton__password-container">
          <div class="skeleton__password skeleton-element"></div>
          <div class="skeleton__password skeleton-element"></div>
        </div>
      </div>
      <div class="profile__wrapper" v-if="$store.getters['user/isProfileDownloaded']">
        <form action="#" method="post" class="profile__form">
        <div class="profile__header container-header">
          Общие данные
        </div>
        <div class="profile__form-group">
          <div class="profile__form-element-box">
            <app-input
                class="profile__input"  id="lastName" label="Фамилия"
                :enable-erase="canUpdateProfile" :readonly="! canUpdateProfile"
                :input-class-list="[{ 'form-element-error' : fieldHasError('last_name') }]"
                v-model:model-value="lastName"
                @erase="clearData" @focus="clearError"
            />
            <span class="profile__form-error-description form-caption-error" v-show="fieldHasError('last_name')" v-html="errorBag.last_name"></span>
          </div>
          <div class="profile__form-element-box">
            <app-input
                class="profile__input"  id="firstName" label="Имя"
                :enable-erase="canUpdateProfile" :readonly="! canUpdateProfile"
                :input-class-list="[{ 'form-element-error' : fieldHasError('first_name') }]"
                v-model:model-value="firstName"
                @erase="clearData" @focus="clearError"
            />
            <span class="profile__form-error-description form-caption-error" v-show="fieldHasError('first_name')" v-html="errorBag.first_name"></span>
          </div>
          <div class="profile__form-element-box">
            <app-input
                class="profile__input"  id="patronymic" label="Отчество"
                :enable-erase="canUpdateProfile" :readonly="! canUpdateProfile"
                :input-class-list="[{ 'form-element-error' : fieldHasError('patronymic') }]"
                v-model:model-value="patronymic"
                @erase="clearData" @focus="clearError"
            />
            <span class="profile__form-error-description form-caption-error" v-show="fieldHasError('patronymic')" v-html="errorBag.patronymic"></span>
          </div>
          <div class="profile__form-element-box">
            <app-input
                class="profile__input"  id="email" label="E-mail" type="email"
                :enable-erase="canUpdateProfile" :readonly="! canUpdateProfile"
                :input-class-list="[{ 'form-element-error' : fieldHasError('email') }]"
                v-model:model-value="email"
                @erase="clearData" @focus="clearError"
            />
            <span class="profile__form-error-description form-caption-error" v-show="fieldHasError('email')" v-html="errorBag.email"></span>
          </div>
          <div class="profile__form-element-box">
            <app-input
                class="profile__input"  id="phone" label="Телефон" type="tel" mask="+7 (###) ###-##-##"
                :enable-erase="canUpdateProfile" :readonly="! canUpdateProfile"
                :input-class-list="[{ 'form-element-error' : fieldHasError('phone') }]"
                v-model:model-value="phone"
                @erase="clearData" @focus="clearError"
            />
            <span class="profile__form-error-description form-caption-error" v-show="fieldHasError('phone')" v-html="errorBag.phone"></span>
          </div>
          <div class="profile__form-element-box" v-if="employeeFormElementShown">
            <app-input
                class="profile__input"  id="disembarkTime" label="Предпочитаемое время отгрузки"
                :enable-erase="canUpdateProfile" :readonly="! canUpdateProfile"
                :input-class-list="[{ 'form-element-error' : fieldHasError('disembark_time') }]"
                v-model:model-value="disembarkTime"
                @erase="clearData" @focus="clearError"
            />
            <span class="profile__form-error-description form-caption-error" v-show="fieldHasError('disembark_time')" v-html="errorBag.disembark_time"></span>
          </div>
          <div class="profile__form-element-box" v-if="employeeFormElementShown">
            <app-input
                class="profile__input"  id="carBoothSize" label="Размер будки (ВхГхШ)"
                :enable-erase="canUpdateProfile" :readonly="! canUpdateProfile"
                :input-class-list="[{ 'form-element-error' : fieldHasError('car_booth_size') }]"
                v-model:model-value="carBoothSize"
                @erase="clearData" @focus="clearError"
            />
            <span class="profile__form-error-description form-caption-error" v-show="fieldHasError('car_booth_size')" v-html="errorBag.car_booth_size"></span>
          </div>
          <div class="profile__form-element-box" v-if="employeeFormElementShown">
            <app-input
                class="profile__input"  id="vehicleLoadCapacity" label="Грузоподъмность машины (кг)"
                :enable-erase="canUpdateProfile" :readonly="! canUpdateProfile"
                :input-class-list="[{ 'form-element-error' : fieldHasError('vehicle_load_capacity') }]"
                v-model:model-value="vehicleLoadCapacity"
                @erase="clearData" @focus="clearError"
            />
            <span class="profile__form-error-description form-caption-error" v-show="fieldHasError('vehicle_load_capacity')" v-html="errorBag.vehicle_load_capacity"></span>
          </div>
          <div class="profile__form-element-box" v-if="employeeFormElementShown">
            <app-select
                class="profile__input" id="allowedTtk" label="Въезд в ТТК"
                :options="[
                {
                  key: 'yes',
                  value: true,
                  display: 'Да',
                  selected: this.allowedTtk === true
                },
                {
                  key: 'no',
                  value: false,
                  display: 'Нет',
                  selected: this.allowedTtk === false
                }
              ]"
                :disabled="! canUpdateProfile" :enable-erase="canUpdateProfile" :select-class-list="[{ 'form-element-error' : fieldHasError('allowed_ttk') }]"
                @update="updateData" @focus="clearError"
            />
            <span class="profile__form-error-description form-caption-error" v-show="fieldHasError('allowed_ttk')"  v-html="errorBag.allowed_ttk"></span>
          </div>
          <div class="profile__form-element-box profile__form-address-element-box">
            <app-input
                class="profile__input profile__input-address"  id="address" label="Адрес проживания"
                :enable-erase="canUpdateProfile" :readonly="! canUpdateProfile"
                :input-class-list="[{ 'form-element-error' : fieldHasError('address') }]"
                v-model:model-value="address"
                @erase="clearData" @focus="clearError"
            />
            <app-text-area
                class="profile__text-area-container"  id="address" label="Адрес проживания"
                :enable-erase="canUpdateProfile" :readonly="! canUpdateProfile"
                :text-area-class-list="['profile__text-area']" :input-class-list="[{ 'form-element-error' : fieldHasError('address') }]"
                v-model:model-value="address"
                @erase="clearData" @focus="clearError"
            ></app-text-area>
            <span class="profile__form-error-description form-caption-error" v-show="fieldHasError('address')" v-html="errorBag.address"></span>
          </div>
        </div>
        <div class="profile__header container-header">
          Смена пароля
        </div>
          <div class="profile__form-group">
            <div class="profile__form-element-box profile__form-password-element-box">
              <app-input
                  class="profile__input profile__input-password"  id="password" label="Новый пароль" type="password"
                  :enable-erase="canUpdateProfile" :readonly="! canUpdateProfile"
                  :input-class-list="[{ 'form-element-error' : fieldHasError('password') }]"
                  v-model:model-value="password"
                  @erase="clearData" @focus="clearError"
              />
              <span class="profile__form-error-description form-caption-error" v-show="fieldHasError('password')" v-html="errorBag.password"></span>
            </div>
            <div class="profile__form-element-box profile__form-password-element-box">
              <app-input
                  class="profile__input profile__input-password"  id="passwordConfirmation" label="Подтвердить новый пароль" type="password"
                  :enable-erase="canUpdateProfile" :readonly="! canUpdateProfile" v-model:model-value="passwordConfirmation"
                  @erase="clearData"
              />
            </div>
          </div>
          <div class="profile__control-group">
            <div class="profile__action-group">
              <app-checkbox
                  label="Получать уведомления по электронной почте" class="profile__is-notifiable"
                  :disabled="! canUpdateProfile" v-model:model-value="isNotifiable"
              />
              <div class="profile__upload-avatar" @click="activateCropper">
                <div class="profile__upload-avatar-caption">
                  Загрузить фотографию
                </div>
              </div>
            </div>
            <div class="profile__button-group">
              <app-button class="profile__button" is-submit :disabled="! canUpdateProfile" @click.prevent="updateProfile">
                Сохранить
              </app-button>
              <app-button button-style="button_cancel" class="profile__button" :disabled="! canUpdateProfile" @click="eraseData">
                Сбросить
              </app-button>
            </div>
          </div>
        </form>
      </div>
    </container>
  </wrapper>
  <avatar-cropper v-model="cropTrigger" :labels="{ submit: 'Загрузить', cancel: 'Отмена' }" :upload-handler="uploadAvatar"/>
</template>

<script>
import Wrapper from '@/layouts/Wrapper'
import AppHeader from '@/components/AppHeader'
import Container from '@/layouts/Container'
import AppInput from '@/components/AppInput'
import AppSelect from '@/components/AppSelect'
import AppCheckbox from '@/components/AppCheckbox'
import AppButton from '@/components/AppButton'
import AppTextArea from '@/components/AppTextArea'
import AvatarCropper from 'vue-avatar-cropper'
import { updateProfile, uploadAvatar } from '@/api/auth'
import { isError } from '@/utils/request'
import { camelToSnake } from '@/utils/helpers'

export default {
  name: 'Profile',
  components: {
    AppCheckbox,
    Wrapper,
    AppHeader,
    Container,
    AppInput,
    AppSelect,
    AppButton,
    AppTextArea,
    AvatarCropper
  },
  data() {
    return {
      lastName: this.$store.state.user.profile.last_name,
      firstName: this.$store.state.user.profile.first_name,
      patronymic: this.$store.state.user.profile.patronymic,
      email: this.$store.state.user.profile.email,
      phone: this.$store.state.user.profile.extra.phone,
      disembarkTime: this.$store.state.user.profile.extra.disembark_time,
      carBoothSize: this.$store.state.user.profile.extra.car_booth_size,
      vehicleLoadCapacity: this.$store.state.user.profile.extra.vehicle_load_capacity,
      allowedTtk: this.$store.state.user.profile.extra.allowed_ttk,
      address: this.$store.state.user.profile.extra.address,
      password: '',
      passwordConfirmation: '',
      isNotifiable: this.$store.state.user.profile.is_notifiable,
      cropTrigger: false,
      errorBag: {}
    }
  },
  methods: {
    clearData(field) {
      this[field] = null
    },

    updateData(event) {
      this[event.field] = event.value
    },

    eraseData() {
      this.lastName = ''
      this.firstName = ''
      this.patronymic = ''
      this.email = ''
      this.phone = ''
      this.disembarkTime = ''
      this.carBoothSize = ''
      this.vehicleLoadCapacity = ''
      this.address = ''
    },

    fieldHasError(field) {
      return Object.prototype.hasOwnProperty.call(this.errorBag, field)
    },

    clearError(event) {
      const field = camelToSnake(event.target.id)

      if (Object.prototype.hasOwnProperty.call(this.errorBag, field)) {
        delete this.errorBag[field]
      }
    },

    async updateProfile() {
      this.errorBag = {}

      const data = {
        first_name: this.firstName,
        last_name: this.lastName,
        patronymic: this.patronymic,
        email: this.email,
        phone: this.phone,
        vehicle_load_capacity: this.vehicleLoadCapacity,
        car_booth_size: this.carBoothSize,
        address: this.address,
        disembark_time: this.disembarkTime,
        allowed_ttk: this.allowedTtk,
        is_notifiable: this.isNotifiable
      }

      if (this.password !== '' && this.password !== null) {
        data.password = this.password
        data.password_confirmation = this.passwordConfirmation
      }

      const response = await updateProfile(this.$store.state.user.token, data)

      if (isError(response.status)) {
        if (response.status === 422) {
          for (const [key, value] of Object.entries(response.data.errors.validation)) {
            for (const error of value) {
              const curKey = key.replace('data.', '')

              if (Object.prototype.hasOwnProperty.call(this.errorBag, curKey)) {
                this.errorBag[curKey] += `${error}<br>`
              } else {
                this.errorBag[curKey] = `${error}<br>`
              }
            }
          }
        } else {
          this.$notify({
            type: 'error',
            text: response.data.errors.title
          })
        }
      } else {
        this.$notify({
          type: 'success',
          text: response.data.meta.message
        })
      }

      this.password = ''
      this.passwordConfirmation = ''
    },

    activateCropper() {
      this.cropTrigger = true
    },

    async uploadAvatar(cropper) {
      const imgData = cropper.getCroppedCanvas().toDataURL()
      const file = this.dataURLtoFile(imgData, 'upload.png')

      const response = await uploadAvatar(this.$store.state.user.token, file)

      if (isError(response.status)) {
        if (response.status !== 422) {
          this.$notify({
            type: 'error',
            text: response.data.errors.title
          })
        }
      } else {
        this.$notify({
          type: 'success',
          text: response.data.meta.message
        })

        this.$store.dispatch('user/getProfile')
      }
    },

    dataURLtoFile(url, filename) {
      const arr = url.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, {type:mime})
    }
  },
  computed: {
    profileDownloaded() {
      return this.$store.getters['user/isProfileDownloaded']
    },

    employeeFormElementShown() {
      return this.$store.state.user.profile.role.slug === 'driver'
    },

    canUpdateProfile() {
      return ['super_admin', 'project_admin'].indexOf(this.$store.state.user.profile.role.slug) !== -1
    }
  },
  watch: {
    profileDownloaded() {
      this.lastName = this.$store.state.user.profile.last_name
      this.firstName = this.$store.state.user.profile.first_name
      this.patronymic = this.$store.state.user.profile.patronymic
      this.isNotifiable = this.$store.state.user.profile.is_notifiable
      this.email = this.$store.state.user.profile.email
      this.phone = this.$store.state.user.profile.extra.phone
      this.disembarkTime = this.$store.state.user.profile.extra.disembark_time
      this.carBoothSize = this.$store.state.user.profile.extra.car_booth_size
      this.vehicleLoadCapacity = this.$store.state.user.profile.extra.vehicle_load_capacity
      this.allowedTtk = this.$store.state.user.profile.extra.allowed_ttk
      this.address = this.$store.state.user.profile.extra.address
    }
  }
}
</script>

<style lang="scss" scoped>
.profile {
  margin-bottom: 14px;
}

.profile__form {
  padding: 25px 20px 20px;
}

.profile__header {
  margin-bottom: 20px;
}

.profile__form-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile__form-group:not(:last-child) {
  margin-bottom: 25px;
}

.profile__form-element-box {
  flex-basis: 100%;
}

.profile__input {
  width: 100%;
}

.profile__form-error-description {
  display: block;
  margin-top: 10px;
}

.profile__text-area-container {
  width: 100%;
}

.profile__input-address {
  display: none;
}

.profile__control-group {
  display: flex;
  flex-direction: column;
}

.profile__action-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 25px;
}

.profile__is-notifiable {
  max-width: 214px;
}

.profile__upload-avatar {
  position: relative;
  color: #333;
  cursor: pointer;

  &::before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 20px;
    background-image: url("../assets/svg/upload.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.profile__upload-avatar-caption {
  display: inline-block;
  max-width: 100px;
  margin-left: 10px;
  font-size: 13px;
}

.profile__button-group {
  display: flex;
  justify-content: space-between;
}

.profile__button {
  flex-basis: 47.5%;
}

/* skeleton */
.skeleton {
  padding: 25px 20px 20px;
}

.skeleton__header {
  width: 127px;
  height: 26px;
  margin-bottom: 20px;
}

.skeleton__info-container, .skeleton__password-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.skeleton__info-container {
  margin-bottom: 25px;
}

.skeleton__info, .skeleton__password {
  width: 100%;
  height: 50px;
}

@media screen and (min-width: 576px) {
  .profile {
    margin-bottom: 28px;
  }

  .profile__form {
    padding: 25px 20px;
  }

  .profile__form-group {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .profile__form-element-box {
    flex-basis: calc(50% - 10px);
  }

  .profile__form-address-element-box {
    flex-basis: 100%;
  }

  .profile__input-address {
    display: block;
  }

  .profile__text-area-container {
    display: none;
  }

  .profile__control-group {
    flex-direction: row;
    gap: 20px;
  }

  .profile__action-group {
    margin: 0;
  }

  .profile__action-group, .profile__button-group {
    flex-basis: 50%;
  }

  .profile__upload-avatar-caption {
    max-width: none;
    font-size: 15px;
  }

  /* skeleton */
  .skeleton {
    padding: 25px 20px;
  }

  .skeleton__info-container, .skeleton__password-container {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .skeleton__info, .skeleton__password {
    flex-basis: calc(50% - 10px);
    height: 58px;
  }
}

@media screen and (min-width: 768px) {
  .profile__form-group {
    gap: 30px;
  }

  .profile__form-group:not(:last-child) {
    margin-bottom: 35px;
  }

  .profile__form-element-box {
    flex-basis: calc(50% - 15px);
  }

  .profile__form-address-element-box {
    flex-basis: 100%;
  }

  .profile__control-group {
    gap: 30px;
  }

  .profile__action-group {
    gap: 20px;
  }

  .profile__upload-avatar-caption {
    font-size: 16px;
  }

  /* skeleton */
  .skeleton__info, .skeleton__password {
    height: 68px;
  }
}

@media screen and (min-width: 992px) {
  .profile__form {
    padding: 30px;
  }

  .profile__form-element-box {
    flex-basis: calc(100% / 3 - 20px);
  }

  .profile__form-password-element-box {
    flex-basis: calc(50% - 15px);
  }

  .profile__form-address-element-box {
    flex-basis: 100%;
  }

  .profile__action-group {
    flex-direction: row;
    gap: 30px;
  }

  .profile__upload-avatar-caption {
    max-width: 100px;
  }

  .profile__button-group {
    justify-content: right;
  }

  .profile__button {
    flex-basis: 162px;
  }

  .profile__button:not(:last-child) {
    margin-right: 30px;
  }

  /* skeleton */
  .skeleton {
    padding: 30px;
  }

  .skeleton__info {
    flex-basis: calc(100% / 3 - 20px);
  }
}

@media screen and (min-width: 1200px) {
  .profile__form {
    padding: 40px;
  }

  /* skeleton */
  .skeleton {
    padding: 40px;
  }
}
</style>

<style>
.profile__text-area {
  height: 110px;
  resize: none;
}
</style>